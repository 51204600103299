import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import { Store } from 'components/Store';
import { STORE_SEO } from '../utils/seo';

import { GetStoreIndexQuery } from 'types';

interface IStoreIndexProps {
  data: GetStoreIndexQuery;
  location: Location;
}

const StorePage: React.FC<IStoreIndexProps> = ({ data }) => {
  return (
    <Layout {...STORE_SEO}>
      <Store products={data.allShopifyProduct} />
    </Layout>
  );
};

export const storeIndexQuery = graphql`
  query GetStoreIndex {
    allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          handle
          createdAt
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            priceV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export default StorePage;
