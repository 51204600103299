import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { buildStoreProductRouteFromHandle } from 'utils/routes';
import { getPrice } from 'utils/store';
import { GetStoreIndexQuery } from 'types';

import styles from './Store.module.css';
import Loading from 'components/common/Loading';

interface IStoreIndexProps {
  products: GetStoreIndexQuery['allShopifyProduct'];
}

export const Store: React.FC<IStoreIndexProps> = ({ products }) => {
  return (
    <div className={styles.page}>
      <h1 className={styles.title}>Store</h1>
      <div className={styles.products}>
        <div className={styles.productGrid}>
          {products.edges ? (
            products.edges.map(
              ({
                node: {
                  id,
                  handle,
                  title,
                  images: [firstImage],
                  variants: [firstVariant],
                },
              }) => {
                const url = buildStoreProductRouteFromHandle(handle);
                const {
                  priceV2: { amount, currencyCode },
                } = firstVariant;

                return (
                  <div className={styles.product} key={id}>
                    <Link to={url} className={styles.productImageContainer}>
                      {firstImage && firstImage.localFile && (
                        <Img
                          fluid={firstImage.localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </Link>
                    <div className={styles.productInfo}>
                      <p className={styles.productInfoInnerContainer}>
                        <Link to={url} className={styles.productTitle}>
                          {title}
                        </Link>
                      </p>
                      <p className={styles.productInfoInnerContainer}>
                        <Link to={url} className={styles.productPriceTag}>
                          {getPrice({
                            amount,
                            currencyCode,
                          })}
                        </Link>
                      </p>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};
