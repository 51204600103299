interface IGetPriceParams {
  amount: string;
  currencyCode?: string;
}

export const getPrice = ({
  amount,
  currencyCode = 'USD',
}: IGetPriceParams): string =>
  Intl.NumberFormat(undefined, {
    currency: currencyCode ? currencyCode : 'USD',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(parseFloat(amount ? amount : '0'));

export const getErrorType = error => {
  return error.field[0]?.value;
};

export const isDiscountCodeError = error =>
  getErrorType(error) === 'discountCode';
